/* Define the background color pulse animation */
@keyframes backgroundPulse {
    0% {
      background-color: #e0f7fa; /* Default background */
    }
    50% {
      background-color: #b2ebf2; /* Lighter pulse background */
    }
    100% {
      background-color: #e0f7fa; /* Default background */
    }
  }
  
  @keyframes backgroundPulseInplay {
    0% {
      background-color: #e2fae0; /* Default background */
    }
    50% {
      background-color: #a8f4ba; /* Lighter pulse background */
    }
    100% {
      background-color: #dcfadf; /* Default background */
    }
  }
  
  @keyframes textSlide {
    0% {
     left:100%
    }

    100% {
     left:-100%
    }
  }
  
  /* Style for the cell with background animation */
  .matchTypeCell {
    overflow: hidden;
    color: black;
    background-color: #e0f7fa; /* Default background */
    padding: 10px;
    border-radius: 5px;
    transition: background-color 0.5s ease;
    animation: backgroundPulse 3s ease-in-out infinite; /* Animate the background */
  }
  
  /* Different background if value is N/A */
  .matchTypeCell.na {
    background-color: #ffebee; /* Background color when value is N/A */
    animation: none; /* Disable animation for N/A cells */
  }
  
  /* Additional styles for different match statuses */
  .matchTypeCell.Inplay {
    color: rgb(2, 189, 2);
    /* background-color: #539655; Light green for active matches */
    animation: backgroundPulseInplay 3s ease-in-out infinite
  }

  .matchTypeCell.Inplay .text{
    position: relative;
    /* background-color: #539655; Light green for active matches */
    animation: textSlide 2s linear infinite; /* Add text slide animation */
  }
  
  .matchTypeCell.Upcoming {
    background-color: #fff9c4; /* Light yellow for ongoing matches */
    animation: backgroundPulse 3s ease-in-out infinite;
  }
  
  .matchTypeCell.completed {
    background-color: #e0e0e0; /* Light gray for completed matches */
    animation: none; /* No animation for completed matches */
  }
  