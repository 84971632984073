.custom-dropdown-toggle {
    background-color: transparent;
    border: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
    color: inherit;
  }

  .custom-dropdown-toggle::after {
    display: none !important; /* Hide the default arrow */
    content: ""; /* Remove the default content */
  }