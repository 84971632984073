.sub-service-title:after {
    background: #1f2e69;
    height: 1px;
    position: absolute;
    left: 0;
    top: 10px;
    width: 100%;
    content: "";
    z-index: 0;
  }
   
  .sub-service-title {
    position: relative;
  }
   
  .sub-service-title h3 {
    font-size: 18px;
    font-weight: 700;
    color: #1f2e69;
    margin: 20px 0 20px;
    position: relative;
    z-index: 1;
  }
   
  .sub-service-title span {
    padding: 0 8px 0 0;
    background: #fff;
    padding: 0px 10px;
    border-radius: 1px;
    display: inline-block;
  }
   
  .subservice-row {
    display: table;
    width: 100%;
    border-bottom: 1px solid #e6e6e6;
  }
   
  .service-title {
    display: table-cell;
    width: 40%;
    padding: 2px 20px 2px 5px;
    font-weight: 600;
    vertical-align: middle;
    height: 50px;
    font-size: 14px;
    /* color: #000; */
  }
   
  .service-title12 {
    display: table-cell;
    width: 12%;
    padding: 2px 20px 2px 5px;
    font-weight: 600;
    vertical-align: middle;
    height: 50px;
    font-size: 14px;
    /* color: #000; */
  }

  .sub-heading-title{
    font-size: 14px;
  }
   
  .service-view {
    display: table-cell;
    width: 60%;
    padding: 2px 20px 2px 5px;
    /* color: #000; */
    vertical-align: middle;
    height: 50px;
  }
  .service-view12 {
    display: table-cell;
    width: 80%;
    padding: 2px 20px 2px 5px;
    /* color: #000; */
    vertical-align: middle;
    height: 50px;
  }



.wallet-table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    font-size: 16px;
    text-align: left;
}

.wallet-table th,
.wallet-table td {
    padding: 12px 15px;
    border: 1px solid #ddd;
    font-size: 13px;
}

/* .wallet-table th {
    background-color: #f2f2f2;
    font-weight: bold;
} */

/* .wallet-table tbody tr {
    border-bottom: 1px solid #ddd;
} */

/* .wallet-table tbody tr:nth-of-type(even) {
    background-color: #f9f9f9;
} */

/* .wallet-table tbody tr:last-of-type {
    border-bottom: 1px solid #009879;
} */

/* Responsive for smaller devices */

/* 
.subservice-row {
    display: flex;
    justify-content: space-between;
    padding: 8px 0;
}

.service-title {
    font-weight: bold;
    color: #333;
}

.service-view {
    color: #666;
    font-size: 14px;
}
 */
