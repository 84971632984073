
.irs--flat {
  .irs-min,
  .irs-max {
    color: $secondary;
    background-color: $gray-200;
    border-radius: 3px;
  }

  .irs-from,
  .irs-to,
  .irs-single {
    background-color: $primary;
    border-radius: 3px;

    &::before { border-top-color: $primary; }
  }

  .irs-line {
    background-color: $border-color;
    @include border-radius();
  }

  .irs-bar { background-color: $primary; }
  .irs-bar--single {
    border-top-left-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
  }

  .irs-handle {
    > i:first-child { background-color: darken($primary, 5%); }

    &:hover,
    &:focus,
    &:active {
      cursor: w-resize;
      > i:first-child { background-color: darken($primary, 7%); }
    }

    &.state_hover {
      &,&:hover,&:focus,&:active {
        > i:first-child { background-color: darken($primary, 7%); }
      }
    }
  }

  .irs-grid-text { color: $secondary; }
  .irs-grid-pol { background-color: $border-color; }
}

// round skin
.irs--round {
  .irs-min,
  .irs-max,
  .irs-from,
  .irs-to {
    font-size: 10px;
    @include border-radius();
  }

  .irs-min,
  .irs-max {
    background-color: $gray-200;
    color: $secondary;
  }

  .irs-grid-text {
    font-size: 10px;
    color: $secondary;
  }

  .irs-grid-pol { background-color: $border-color; }

  .irs-handle {
    border-color: $primary;
    border-radius: 8px;
  }

  .irs-from,
  .irs-to,
  .irs-bar { background-color: $primary; }
}

// square skin
.irs--square {
  .irs-line,
  .irs-grid-pol { background-color: $gray-200; }

  .irs-min,
  .irs-max {
    background-color: $gray-200;
    font-size: 10px;
    color: $secondary;
  }

  .irs-grid-text {
    font-size: 10px;
    color: $secondary;
  }

  .irs-from,
  .irs-to {
    background-color: $gray-800;
    font-size: 10px;
  }

  .irs-handle { border-color: $gray-800; }
  .irs-bar { background-color: $gray-800; }
}
