.content { padding: 20px; }
.number-grid, .jackpot-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin: 10px 0;
}
.number-ball {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: #1C2437;  if  */
    background-color: #eee;
    border-radius: 50%;
    cursor: pointer;
    font-weight: bold;
    font-size: 1.2em;
}
body.dark-mode .number-ball{
    background-color: #1C2437;  
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
    font-weight: bold;
    font-size: 1.2em;

}
.number-ball.selected,body.dark-mode .number-ball.selected{
    background-color: #28a745;
    color: white;
}