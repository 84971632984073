.progress {
  height: 12px;

  &.h-1 { height: 2px; }
  &.h-2 { height: 5px; }
  &.h-3 { height: 8px; }
  &.h-4 { height: 15px; }
  &.h-5 { height: 20px; }
}

.progress-bar {
  font-family: $font-family-sans-serif;
  font-size: 9px;
}
