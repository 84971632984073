
.customTable th, .customTable td {
  background: transparent !important;
color: #41505f !important;
}
/* Default (light mode) styles */


.customFilter{
    background: #192030;
    border-color: transparent;
    color: white !important;
    box-shadow: none;
}

.page-title-box .page-title {
  font-size: 18px;
  margin: 0;
  line-height: 40px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
/* styles.css */
.date-range-picker-light {
  background-color: white;
  color: black;
}


/* .rdrDayNumber span {
  color:rgb(232, 232, 233) !important;
} */
.date-range-picker-dark .rdrDayNumber span {
  color: rgb(255, 255, 255) !important;
}
.date-range-picker-dark .rdrDayPassive .rdrDayNumber span {
  color: #787a7b !important;
}
.date-range-picker-dark {
  background-color: #1C2437 !important;
 
}
.date-range-picker-dark .rdrDateDisplayWrapper  {
  background-color: #0f1114 !important;
}
.date-range-picker-dark .rdrDateDisplayItem{
  background-color: #21252c !important;
  color: white;
}

.custom-form-css{
  
  padding: 1px 10px !important;
  border-radius: 4px !important;
}

.rdrDayDisabled {
  background-color:transparent !important;
}

.date-range-picker {
  position: absolute;
  /* z-index: 1000; */
  top: 38px;
  left: 0;
}
@media (min-width:1100px) {
  .payinFilter .date-range-picker,.betlist .date-range-picker,.wallet .date-range-picker {
    position: absolute;
    /* z-index: 1000; */
    top: 38px;
    left: -94px;
  }
}