.dropzone {
    border: 2px dashed #dee2e6;
    border-radius: 8px;
    padding: 2rem;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s ease;
    /* background-color: #f8f9fa; */
}

.dropzone.dragging {
    border-color: #0d6efd;
    background-color: rgba(13, 110, 253, 0.05);
}

.dropzone:hover {
    border-color: #adb5bd;
}

.dropzone-content {
    pointer-events: none;
}

.upload-icon {
    color: #6c757d;
    margin-bottom: 1rem;
}

.file-info {
    /* background-color: #f8f9fa; */
    border-radius: 6px;
    padding: 0.75rem;
    border: 1px solid #dee2e6;
}

.file-name {
    font-weight: 500;
    font-size: 0.9rem;
}

.file-size {
    font-size: 0.8rem;
}

.current-version {
    font-size: 0.85rem;
}
.file-details {
    text-align: left;
}

.file-header {
    display: flex;
    align-items: center;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid #eee;
}

.file-metadata {
    /* background-color: #f8f9fa; */
    border-radius: 6px;
    padding: 1rem;
}

.metadata-row {
    display: flex;
    margin-bottom: 0.5rem;
}

.metadata-label {
    font-weight: 500;
    width: 100px;
    /* color: #495057; */
}

.metadata-value {
    flex: 1;
    /* color: #212529; */
}

.current-version .alert {
    padding: 0.5rem 1rem;
    font-size: 0.85rem;
}