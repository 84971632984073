[data-skin="dark"] {
    .form-control {
        background-color: $dark-highlight;
        border-color: lighten($border-color-dark, 3%);
        color: rgba(#fff, .65);
    
        &:focus {
          box-shadow: none;
          background-color: lighten($dark-highlight, 3%);
          border-color: lighten($border-color-dark, 3%);
          color: rgba(#fff, .65);
        }
    
        &:disabled,
        &[readonly] {
          background-color: $dark-primary;
        }
    
        &::file-selector-button,
        &::-webkit-file-upload-button {
          background-color: lighten($dark-highlight, 3%);
          color: #fff;
        }
    
        &:hover:not(:disabled):not([readonly])::file-selector-button {
          background-color: $dark-highlight;
        }
    
        &:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
          background-color: $dark-highlight;
        }
    
        &::placeholder { color: rgba(#fff, .3); }
      }
    
      .form-select {
        background-color: $dark-highlight;
        border-color: lighten($border-color-dark, 3%);
        color: rgba(#fff, .65);
    
        &:focus {
          box-shadow: none;
          border-color: lighten($border-color-dark, 6%);
        }
    
        &:disabled {
          background-color: $dark-primary;
        }
      }
    
      .form-check-input {
        border-color: lighten($border-color-dark, 3%);
        background-color: $dark-primary;
    
        &:focus { border-color: lighten($border-color-dark, 6%); }
      }
    
      .form-switch .form-check-input {
        background-image:url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='rgba(255,255,255,0.45)'/></svg>");
        // background-color: '#506fd9';

      }
    .form-check-input:checked{
      background-color: #506fd9;
      border-color: #506fd9;
    }
      .form-range {
        &::-webkit-slider-runnable-track,
        &::-moz-range-track {
          background-color: $border-color-dark;
        }
      }
    
      .input-group-text {
        background-color: lighten($dark-highlight, 1.5%);
        border-color: lighten($border-color-dark, 3%);
        color: inherit;
      }
    
      .form-search {
        background-color: $dark-highlight;
        border: 1px solid lighten($border-color-dark, 3%);
        box-shadow: none;
    
        &.onfocus {
          background-color: lighten($dark-highlight, 3%);
        }
      }
    }