[data-skin="dark"] {
    .table {
        background-color: #1c2437 !important;
        border-bottom-color: #222b41 !important;
        color: rgba(255, 255, 255, 0.6);
        thead tr th {
          font-weight: $font-weight-semibold;
          font-size: $font-size-sm;
        color: rgba(255, 255, 255, 0.6) !important;
    }
    
    tbody tr td {
        color: rgba(255, 255, 255, 0.6) !important;
    }
      
        tbody th { font-weight: $font-weight-semibold; }
      
        &:not(.table-borderless) {
          > :not(:first-child) {
            border-top-width: 1.5px;
            border-top-color: $gray-400;
          }
        }
      }
      
      .table-striped {
        > tbody > tr:nth-of-type(2n+1) > * {
          box-shadow: inset 0 0 0 9999px rgba($gray-200, .6);
        }
      }
      
      .table-hover {
        > tbody > tr:hover > * {
          box-shadow: inset 0 0 0 9999px rgba($gray-200, .85);
        }
      }
      
      .table-active {
        td,th { box-shadow: inset 0 0 0 9999px rgba($gray-200, .85); }
      }
      
      .table-primary,
      .table-success,
      .table-warning,
      .table-danger,
      .table-info {
        > :not(:first-child) { border-top-color: #fff; }
      
        tbody td,
        tbody th {
          border-color: #fff;
        }
      }
      
      .table-responsive {
        min-height: calc(100vh - 280px)
      }
      
}