/* .balance-card {
    width: 150px;
    
    border: 1px solid #ddd;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
.balance-card .card-title {
    margin-bottom: 0.5rem;
    font-size: 1.25rem;
}
.balance-card .card-text {
    font-size: 1.5rem;
    font-weight: bold;
} */
.balance-card .card-title {
    font-size: 1.2rem; /* Default size */
  }
  .balance-card .card-text {
    font-size: 1rem; /* Default size */
  }

  @media (max-width: 1200px) {
    .balance-card .card-title {
      font-size: 1.1rem;
    }
    .balance-card .card-text {
      font-size: 0.9rem;
    }
  }

  @media (max-width: 992px) {
    .balance-card .card-title {
      font-size: 1rem;
    }
    .balance-card .card-text {
      font-size: 0.8rem;
    }
  }

  @media (max-width: 768px) {
    .balance-card .card-title {
      font-size: 0.9rem;
    }
    .balance-card .card-text {
      font-size: 0.7rem;
    }
  }

  @media (max-width: 576px) {
    .balance-card .card-title {
      font-size: 0.8rem;
    }
    .balance-card .card-text {
      font-size: 0.6rem;
    }
  }
